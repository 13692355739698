import React, { ReactElement } from 'react';
import { NavigationMenuProps, Root } from '@radix-ui/react-navigation-menu';

import styles from './Menu.module.scss';

type MenuProps = NavigationMenuProps;

const Menu = ({ children, ...props }: MenuProps): ReactElement => (
	<Root className={styles.menu} delayDuration={0} {...props}>
		{children}
	</Root>
);

export default Menu;
