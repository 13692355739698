export const INITIALS_PLACEHOLDER = 'U';

export const NAME_PLACEHOLDER = 'User';

export const AVATAR_VARIATIONS_DATA = [
	{ fullName: 'John Doe', initials: 'JD' },
	{ fullName: 'John Doe James', initials: 'JJ' },
	{ fullName: 'John Doe-James', initials: 'JD' },
	{ fullName: 'John', initials: 'J' },
	{ fullName: 'Doe', initials: 'D' },
	{ fullName: ' ', initials: 'U' },
	{ fullName: '😄alex 😄alan', initials: 'AA' },
	{ fullName: 'John Doe 🔴⚪️🔵', initials: 'JD' },
	{ initials: INITIALS_PLACEHOLDER },
];
