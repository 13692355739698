import React from 'react';

import { MoneyFigure } from '../__primitive__/MoneyFigure';

import { MonetaryValueProps } from './MonetaryValue.types';
import { defaultResolveMonetaryValueParts } from './MonetaryValue.utils';

export const MonetaryValue = (props: MonetaryValueProps): React.ReactElement => {
	const { resolver = defaultResolveMonetaryValueParts, value, ...rest } = props;

	return <MoneyFigure data-testid="moneyFigure" {...resolver(value)} {...rest} />;
};
