import type { ReactElement } from 'react';
import React from 'react';
import type { NavigationMenuTriggerProps } from '@radix-ui/react-navigation-menu';
import { NavigationMenuTrigger } from '@radix-ui/react-navigation-menu';

export type TriggerProps = NavigationMenuTriggerProps;

const Trigger = ({ children, ...props }: TriggerProps): ReactElement => (
	<NavigationMenuTrigger asChild {...props}>
		{children}
	</NavigationMenuTrigger>
);

export default Trigger;
