import { Dispatch, SetStateAction, useState } from 'react';

import { useToast } from '@motorway/motorway-storybook-cra';

import { isUserOTPAuthorised } from 'Services/api/payment';
import { logger } from 'Services/logger/logger';
import usePaymentsMVPEnabled from 'Utilities/hooks/usePaymentsMVPEnabled';
import useUser from 'Utilities/hooks/useUser';

import { getInvoicedMagicLink, handleBillingLink } from './billing.helpers';

type UseBillingApi = {
	isBillingEnabled: boolean;
	onBillingClick: () => Promise<void>;
	onSuccess: () => void;
	portalVisible: boolean;
	setPortalVisible: Dispatch<SetStateAction<boolean>>;
};

export const useBilling = (): UseBillingApi => {
	const { dealerId, hasInvoiceAccess: isBillingEnabled } = useUser();

	const toast = useToast();

	const isPaymentsMVPEnabled = usePaymentsMVPEnabled();
	const [portalVisible, setPortalVisible] = useState<boolean>(false);

	const onSuccess = () => {
		handleBillingLink({ dealerId, getInvoicedMagicLink, toast }).catch((error) => {
			logger.warn({
				context: { error },
				message: error?.message || 'Error handling billing link',
				scope: 'useBilling',
			});
		});
		setPortalVisible(false);
	};

	const onBillingClick = async () => {
		if (isPaymentsMVPEnabled) {
			isUserOTPAuthorised()
				.then(onSuccess)
				.catch(() => {
					setPortalVisible(true);
				});
		} else {
			handleBillingLink({ dealerId, getInvoicedMagicLink, toast }).catch((error) => {
				logger.warn({
					context: { error },
					message: error?.message || 'Error handling billing link',
					scope: 'useBilling',
				});
			});
		}
	};

	return { isBillingEnabled, onBillingClick, onSuccess, portalVisible, setPortalVisible };
};
