import { ReactElement } from 'react';
import { ArrowRightIcon } from '@THC/components/Icons/Arrow';

import cypressIds from 'Components/cypress_ids.json';
import { triggerAccountSettingsLinkClickedEvent } from 'Services/analytics/events';
import { applySessionReplayMask } from 'Services/datadog/datadogRum';
import { MASKED_EVENT_VALUES } from 'Services/datadog/datadogRum.const';
import { PresentationListItem } from 'Storybook/core/Menu';
import Link from 'Storybook/core/Menu/components/Link/Link';
import { AccountMenuHeaderProps } from 'Storybook/local/SiteNavigation/SiteNavigation.types';
import { Variant } from 'Types/variant';
import { applyCypressData } from 'Utilities/helpers';
import ROUTES from 'Utilities/routes';

import styles from './AccountMenuHeader.module.scss';

const { navigation } = MASKED_EVENT_VALUES;
const privacyParams = { menuHeader: applySessionReplayMask({ maskedActionName: navigation.account.menuHeader }) };

export const AccountMenuHeaderLegacy = ({ dealerName, dealershipName }: AccountMenuHeaderProps): ReactElement => (
	<PresentationListItem {...privacyParams.menuHeader} {...applyCypressData(cypressIds.containers.accountMenuHeader)}>
		<p className={styles.dealerName}>{dealerName}</p>
		{dealershipName && (
			<p className={styles.dealershipName} data-testid="dealership-name">
				{dealershipName}
			</p>
		)}
	</PresentationListItem>
);

export const AccountMenuHeader: React.FC<AccountMenuHeaderProps> = ({ dealerName, dealershipName }): ReactElement => (
	<Link
		isListItem
		as={ROUTES.ACCOUNT_SETTINGS.as()}
		href={ROUTES.ACCOUNT_SETTINGS.href}
		icon={ArrowRightIcon}
		isActive={false}
		isIconRight={true}
		onClick={triggerAccountSettingsLinkClickedEvent}
		text={
			<div>
				<p className={styles.dealerName}>{dealerName}</p>
				{dealershipName && (
					<p className={styles.dealershipName} data-testid="dealership-name">
						{dealershipName}
					</p>
				)}
			</div>
		}
		variant={Variant.SECONDARY}
		{...privacyParams.menuHeader}
		{...applyCypressData(cypressIds.containers.accountMenuHeader)}
	/>
);
