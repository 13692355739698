export default {
	error: {
		general: {
			message:
				'It seems we are having trouble sending you a one time passcode. Please try again. If the problem persists, please contact our Payments team on {phone}.',
			title: 'Failed to send one time passcode',
		},
		rate_limit_reached: {
			message:
				'You have reached the maximum number of attempts to send one time passcode in a short period of time. Please wait a few minutes before trying again.',
			title: 'Failed to send one time passcode',
		},
	},
};
