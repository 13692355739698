export default {
	otpForm: {
		content1: 'We have sent a 6 digit one-time passcode to your authentication mobile number ending in {phoneEnd}.',
		content2: 'To continue, please enter the passcode below',
		content3: 'If you have any issues, contact our Client\u00A0Onboarding Team on {phoneContact}.',
		feedbackSuccess: 'Success!',
		title: 'Set up authentication number',
	},
	otpFormSuccess: {
		description:
			"Once Motorway Pay is live, you will have access to all the pages with sensitive information such as Vehicle payment, Wallet, and Seller's details.",
		title: 'Setup complete',
	},
	successToast: {
		content: 'You can now receive one-time passcodes to this number via SMS.',
		heading: 'Authentication number set up!',
	},
	withOtp: {
		buttonSetupNow: 'Set up authentication number',
		content1: 'For security reasons, pages containing sensitive information require authentication to be accessed.',
		content2: 'Set up your authentication number to access it. We’ll send a code to mobile number {phoneEnd}',
		content3: 'If you have any questions, contact our Client\u00A0Onboarding Team on {phoneContact}.',
		errorHeader: 'Something went wrong',
		errorMessage: 'Please try again. If the problem persists, contact our Payments team on {phoneContact}.',
		title: 'Set up authentication number',
	},
	withoutOtp: {
		content1: 'For security reasons, pages containing sensitive information now require authentication to be accessed.',
		content2: 'To set up your authentication number, please contact our Client\u00A0Onboarding Team on {phoneContact}.',
		title: 'Authentication required',
	},
};
