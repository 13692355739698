import { emitterCreateDispatcher } from '@THC/utils/emitter';

import { errorInfoboxSetError } from 'Components/shared/ErrorInfobox/emitter/ErrorInfoboxEmitter.events';
import { SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import { paymentWalletWithdraw } from 'Services/analytics/events/paymentEvents/paymentWalletWithdraw';

import { WITHDRAWAL_ERROR_INFOBOX_ID } from '../WithdrawFunds.helpers';

import { withdrawFundsEmitter } from './WithdrawFundsEmitter';

export const dispatchWithdrawFundsEvent = emitterCreateDispatcher(withdrawFundsEmitter);

export const withdrawFundsUpdateAmount = dispatchWithdrawFundsEvent('amountUpdated');

export const withdrawFundsUpdateError = dispatchWithdrawFundsEvent('error', {
	interceptors: [
		function WithdrawalFundsToErrorBoxInterceptor(error) {
			errorInfoboxSetError({
				id: WITHDRAWAL_ERROR_INFOBOX_ID,
				props: error,
			});
		},
		function trackInfoboxVisibility(error) {
			if (!error) {
				return;
			}

			paymentWalletWithdraw<{
				label: string;
			}>({
				customData: {
					label: JSON.stringify(error.title !== undefined ? error.title : error.message),
				},
				name: 'Funds withdrawal failure infobox shown',
				schemaName: SNOWPLOW_EVENTS.POPUP_APPEAR.SCHEMA,
				version: SNOWPLOW_EVENTS.POPUP_APPEAR.VERSION,
			});
		},
	],
});

export const withdrawFundsTransitionView = dispatchWithdrawFundsEvent('transitionView');
