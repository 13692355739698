import type { ReactElement } from 'react';

import type { NavMenuRouter } from 'Storybook/core/Menu/Menu.types';
import type { User } from 'Types/user';

import GuestMenu from './GuestMenu/GuestMenu';
import UserMenu from './UserMenu/UserMenu';

type DesktopNavigationProps = {
	router: NavMenuRouter;
	user: User & { isLoggedOut: boolean };
};

const DesktopNavigation = ({ router, user }: DesktopNavigationProps): ReactElement => {
	const { isLoggedOut } = user;

	return <>{isLoggedOut ? <GuestMenu router={router} /> : <UserMenu router={router} user={user} />}</>;
};

export default DesktopNavigation;
