import isObject from 'lodash/isObject';

import { ApiErrorResponseForJsend } from 'Services/motorway/apiClient.types';
import captureExceptionWithTeam from 'Utilities/captureException/captureExceptionWithTeam';
import { TEAM } from 'Utilities/consts';
import { isAxiosError } from 'Utilities/isAxiosError';

type OnRequestCatchResponse = ApiErrorResponseForJsend | undefined;

type OnRequestCatch = (props: { error: unknown }) => Promise<OnRequestCatchResponse>;

const handleError = (err: unknown) => {
	const errMessage = isObject(err) && 'message' in err ? `${err.message}` : 'onRequestCatch Error';

	let errRes: ApiErrorResponseForJsend = {
		message: errMessage,
		status: 'error',
	};

	if (isAxiosError<ApiErrorResponseForJsend>(err)) {
		if (err.response?.data) {
			errRes = err.response.data;
		}
	}

	return errRes;
};

export const OTP_RELATED_ERRORS = new Set([
	'verification_code_required',
	'verification_code_failed',
	'rate_limit_reached',
	'verification_limit_reached',
]);

export const onRequestCatch: OnRequestCatch = async ({ error }) => {
	const { message } = handleError(error);

	captureExceptionWithTeam(`OTPRequestCatch - ${message}`, TEAM.PAYMENTS_FE);

	if (message.endsWith('verification_code_required') || message.endsWith('verification_code_failed')) {
		return undefined;
	}

	if (message.endsWith('rate_limit_reached')) {
		return { message: 'rate_limit_reached', status: 'error' };
	}

	if (message.endsWith('verification_limit_reached')) {
		return { message: 'verification_limit_reached', status: 'error' };
	}

	return { message: 'unknown_error', status: 'error' };
};
