import { RefObject } from 'react';

import { OnCloseEvent } from 'Types/drawer';
import { isSSR } from 'Utilities/helpers';

import { FOCUSABLE_ELEMENTS } from './Drawer.const';

export const toggleOverflowHidden = (hidden: boolean): void => {
	if (isSSR() && !document?.body?.style) {
		return;
	}

	if (hidden) {
		document.body.style.setProperty('overflow', 'hidden');
	} else {
		document.body.style.removeProperty('overflow');
	}
};

export const handleKeyboardPress = (
	menuRef: RefObject<HTMLDivElement>,
	onClose: (event: OnCloseEvent) => void,
): (() => void) => {
	const handleKeyboardEvent = (event: KeyboardEvent) => {
		if (event.key === 'Tab') {
			const focusableElements = menuRef.current?.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS);
			const focusableElementsArray = focusableElements ? Array.from(focusableElements) : [];

			const tabbableElements = focusableElementsArray.filter((element) => element.getAttribute('tabindex') !== '-1');

			const firstElement = tabbableElements?.[0];
			const lastElement = tabbableElements?.[tabbableElements.length - 1];

			if (event.shiftKey && document.activeElement === firstElement) {
				lastElement?.focus();
				event.preventDefault();
			} else if (!event.shiftKey && document.activeElement === lastElement) {
				firstElement?.focus();
				event.preventDefault();
			}
		} else if (event.key === 'Escape') {
			onClose(event);
		}
	};

	document.addEventListener('keydown', handleKeyboardEvent);

	return () => {
		document.removeEventListener('keydown', handleKeyboardEvent);
	};
};
