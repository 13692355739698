export default {
	navAccordionItemLabels: {
		bidsAndOffers: {
			active: 'Active',
			didntWin: "Didn't win",
			root: 'Bids & offers',
			underOffer: 'Under offer',
		},
		insights: 'Insights',
		insightsBadge: 'BETA',
		marketplace: 'Marketplace',
		other: {
			billing: 'Billing',
			helpCenter: 'Help',
			pricing: 'Pricing',
			signout: 'Sign out',
			wallet: 'Wallet',
		},
		products: {
			motorwayMove: 'Motorway Move',
			motorwayPay: 'Motorway Pay',
			motorwayTrade: 'Motorway Trade',
			root: 'Products',
		},
		purchases: {
			cancelled: 'Cancelled',
			complete: 'Complete',
			root: 'Purchases',
		},
		trade: 'Your Listings',
	},
	navAccordionItemLabelsLoggedOut: {
		howItWorks: 'How it works',
		marketplace: 'Marketplace',
		signIn: 'Sign in',
	},
};
