import { craftMoneyFigureFn } from './MoneyFigure.types';
import { defaultMoneyFigureFractionFormatter, formatPrice, resolveMoneyFigureVariant } from './MoneyFigure.utils';

export const craftMoneyFigure: craftMoneyFigureFn = ({ base, format, formatPriceProps, fraction, variant }) => {
	const { base: baseFormatter = formatPrice, fraction: fractionFormatter = defaultMoneyFigureFractionFormatter } =
		format || {};

	const formattedBaseValue =
		baseFormatter?.(base ?? '', formatPriceProps) || base?.toString() || formatPrice('0', formatPriceProps);
	const formattedFractionValue = fractionFormatter?.(fraction ?? '') || fraction?.toString() || '.00';

	return {
		classNames: resolveMoneyFigureVariant(variant),
		formattedBaseValue,
		formattedFractionValue,
	};
};
