import React, { useEffect, useState } from 'react';
import { InfoBox } from '@THC/components/InfoBox';

import { filterDataProps } from '@motorway/mw-highway-code/src/utils/helpers';

import { applyCypressData } from 'Utilities/helpers';

import { subscribeErrorInfoboxSetEvent } from './emitter/ErrorInfoboxEmitter.listeners';
import { ErrorInfoboxEvent, ErrorInfoboxProps } from './ErrorInfobox.types';

export const useErrorInfoboxLogic = <E extends ErrorInfoboxEvent = ErrorInfoboxEvent>({
	id,
	initialState,
}: ErrorInfoboxProps<E>): Nilable<ErrorInfoboxEvent> => {
	const [content, setContent] = useState<Nilable<ErrorInfoboxEvent>>(initialState);

	useEffect(() => {
		const handler: Unpacked<Parameters<typeof subscribeErrorInfoboxSetEvent>> = (payload) => {
			// Ensure we only update the state if the event id matches the id passed to the component
			if (payload.id !== id) {
				return;
			}

			setContent(payload.props);
		};

		return subscribeErrorInfoboxSetEvent(handler);
	}, [id]);

	return content;
};

export const ErrorInfobox = <E extends ErrorInfoboxEvent = ErrorInfoboxEvent>(
	props: ErrorInfoboxProps<E>,
): null | React.ReactElement => {
	const content = useErrorInfoboxLogic(props);

	if (!content) {
		return null;
	}

	const dataProps = filterDataProps(props);

	return (
		<InfoBox
			{...applyCypressData('error-infobox')}
			content={content.message}
			data-loading={content.busy ?? props.busy}
			heading={content.title}
			variant={content.severity || 'error'}
			{...dataProps}
		/>
	);
};
