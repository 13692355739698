import React, { HTMLProps, ReactElement } from 'react';

import styles from './PresentationListItem.module.scss';

export type PresentationItemProps = HTMLProps<HTMLDivElement>;

const PresentationListItem = ({ children, ...props }: PresentationItemProps): ReactElement => (
	<div className={styles.niItem} {...props}>
		{children}
	</div>
);

export default PresentationListItem;
