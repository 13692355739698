import cypressIds from 'Components/cypress_ids.json';
import {
	triggerNavigationBidsClickedEvent,
	triggerNavigationInsightsClickedEvent,
	triggerNavigationMarketplaceClickedEvent,
	triggerNavigationPurchasesClickedEvent,
} from 'Services/analytics/events';
import { Content, Link, MenuItem, MenuList, Trigger } from 'Storybook/core/Menu';
import {
	bidsActiveStateCallback,
	insightsActiveStateCallback,
	marketplaceActiveStateCallback,
	purchasesActiveStateCallback,
} from 'Storybook/core/Menu/__stories__/helpers/helpers';
import { NAV_MENU_ITEMS_BIDS_AND_OFFERS, NAV_MENU_ITEMS_PURCHASES } from 'Storybook/core/Menu/Menu.consts';
import type { NavMenuRouter } from 'Storybook/core/Menu/Menu.types';
import { triggerUserSiteNavigationGAEvent } from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';
import DropdownButton from 'Storybook/peripheral/DropdownButton/DropdownButton';
import type { User } from 'Types/user';
import { Variant } from 'Types/variant';
import { LIST_TYPES } from 'Utilities/consts';
import { applyCypressData } from 'Utilities/helpers';
import useVehicleListButtonConfig from 'Utilities/hooks/vehicleList/useVehicleListButtonConfig';
import ROUTES from 'Utilities/routes';

import ProductMenuItem from '../../components/ProductMenuItem/ProductMenuItem';
import { MENU_ITEMS } from '../../SiteNavigation.consts';

import { AccountMenu } from './AccountMenu/AccountMenu';

import styles from './UserMenu.module.scss';

export type UserMenuProps = {
	router: NavMenuRouter;
	user: User & { isLoggedOut: boolean };
};

const UserMenu: React.FC<UserMenuProps> = ({ router, user: { dealerName, name } }) => {
	const marketplaceButton = useVehicleListButtonConfig();

	return (
		<>
			<MenuList>
				<ProductMenuItem
					className={styles.productMenuItem}
					router={router}
					side="left"
					triggerGAEvent={triggerUserSiteNavigationGAEvent}
				/>
			</MenuList>
			<MenuList>
				<MenuItem
					onClick={() => {
						marketplaceButton.onClick();

						triggerNavigationMarketplaceClickedEvent({
							isSaleSlug: marketplaceButton.href.includes(LIST_TYPES.auction),
						});

						triggerUserSiteNavigationGAEvent(
							`marketplace${marketplaceButton.href.includes(LIST_TYPES.auction) ? 'LiveSale' : 'BuyItNow'}`,
						);
					}}
				>
					<Link
						as={marketplaceButton?.as}
						href={marketplaceButton?.href}
						isActive={marketplaceActiveStateCallback(router)}
						isListItem={false}
						text={MENU_ITEMS.MARKETPLACE.LABEL}
						variant={Variant.SECONDARY}
						{...applyCypressData(cypressIds.navAccordion.marketplace)}
					/>
				</MenuItem>
				<MenuItem>
					<Trigger
						asChild
						onClick={() => {
							triggerNavigationBidsClickedEvent();
							triggerUserSiteNavigationGAEvent('bidsAndOffers');
						}}
					>
						<DropdownButton
							isActive={bidsActiveStateCallback(router)}
							text={MENU_ITEMS.BIDS_AND_OFFERS.LABEL}
							variant={Variant.SECONDARY}
							{...applyCypressData(cypressIds.navAccordion.bidsAndOffers.base)}
						/>
					</Trigger>
					<Content
						lists={NAV_MENU_ITEMS_BIDS_AND_OFFERS}
						router={router}
						side="right"
						size="regular"
						variant={Variant.SECONDARY}
						{...applyCypressData(cypressIds.navAccordion.bidsAndOffers.dropdown)}
					/>
				</MenuItem>
				<MenuItem>
					<Trigger
						asChild
						onClick={() => {
							triggerNavigationPurchasesClickedEvent();
							triggerUserSiteNavigationGAEvent('purchases');
						}}
					>
						<DropdownButton
							isActive={purchasesActiveStateCallback(router)}
							text={MENU_ITEMS.PURCHASES.LABEL}
							variant={Variant.SECONDARY}
							{...applyCypressData(cypressIds.navAccordion.purchases.base)}
						/>
					</Trigger>
					<Content
						lists={NAV_MENU_ITEMS_PURCHASES}
						router={router}
						side="right"
						size="regular"
						variant={Variant.SECONDARY}
						{...applyCypressData(cypressIds.navAccordion.purchases.dropdown)}
					/>
				</MenuItem>
				<MenuItem
					onClick={() => {
						triggerNavigationInsightsClickedEvent();
						triggerUserSiteNavigationGAEvent('insights');
					}}
				>
					<Link
						as={ROUTES.INSIGHTS_LANDING.as()}
						href={ROUTES.INSIGHTS_LANDING.href}
						isActive={insightsActiveStateCallback(router)}
						isListItem={false}
						text={MENU_ITEMS.INSIGHTS.LABEL}
						variant={Variant.SECONDARY}
						{...applyCypressData(cypressIds.navAccordion.insights)}
					/>
				</MenuItem>

				<AccountMenu dealerName={name} dealershipName={dealerName} router={router} />
			</MenuList>
		</>
	);
};

export default UserMenu;
