export default {
	errors: {
		approvalNeeded: {
			message:
				'For your security this request is being checked by Motorway. Feel\u00A0free to leave this page, you will receive an email once the withdrawal has been completed.',
			title: 'Approval in progress',
		},
		motorway: {
			'pay-withdrawals': {
				beneficiary_not_found: {
					message:
						'We’re missing a few details about the bank account you’d like to send funds to. To process your withdrawal, please contact our Payments team on {phone}.',
					title: 'Bank account details missing',
				},
				inflight_withdrawal_exists: {
					message: 'You already have a withdrawal in progress. Please wait for it to complete before making another.',
					title: 'Another withdrawal in progress',
				},
				insufficient_funds: {
					message: 'The withdrawal cannot be higher than your Wallet balance',
					title: 'Insufficient funds',
				},
				max_allowed_withdrawals_exceeded_for_today: {
					message: 'Try again tomorrow or contact our Payments team on {phone}.',
					title: 'You’ve reached your daily limit for withdrawals',
				},
			},
		},
		postProcessing: {
			message:
				'Sorry, something went wrong. Please try again. If the issue persists, contact our Payments team on {phone}.',
			title: 'Unable to process withdrawal',
		},
		preProcessing: {
			message:
				'Sorry, something went wrong. Please try again. If the issue persists, contact our Payments team on {phone}.',
			title: 'Unable to retrieve data',
		},
		required: 'Enter a withdrawal amount to continue',
		// too_* matches Zod errors
		too_big: 'The withdrawal cannot be higher than your Wallet balance',
		too_small: 'The minimum withdrawal is £1.00',
	},
	form: {
		desc: 'Remember to leave enough funds in your Wallet to pay for upcoming purchases.',
		heading: 'Withdraw funds',
		label: 'Withdrawal amount',
	},
	notEnrolled: {
		error: {
			message:
				'We’re missing a few details about the bank account you’d like to send funds to. To process your withdrawal, please contact our Payments team on {phone}.',
			title: 'Bank account details missing',
		},
	},
	otp: {
		desc: 'We have sent a six-digit one-time passcode to your mobile number ending {number}. To continue, please enter the passcode below.',
		title: 'Authorise withdrawal',
	},
	success: {
		message: 'You sent {amount} to your bank account ending in {accountNumber}.',
		title: 'Withdraw successfully sent!',
	},
	withdrawFundsAside: {
		accountNumber: 'Account number',
		balance: 'Your wallet balance',
		balanceAfter: 'Balance after withdrawal',
		first: 'The funds you withdraw will be sent to the account below.',
		last: `If you need to change the account that receives withdrawals from your wallet, contact the Payments team by emailing {email}`,
		sortCode: 'Sort code',
	},
};
