import { MDText } from 'i18n-react';

import { toast } from '@motorway/mw-highway-code/pre-alpha';

import LocalTexts from './AuthenticationFeedback.texts';

export const OTP_SUCCESS_TOAST_DURATION = 5000;

const LocalT = new MDText(LocalTexts);

export const triggerSuccessOTPToast = (): void => {
	toast({
		content: LocalT.translate('successToast.content'),
		duration: OTP_SUCCESS_TOAST_DURATION,
		heading: LocalT.translate('successToast.heading'),
		variant: 'success',
	});
};
