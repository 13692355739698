/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isChromatic from 'chromatic/isChromatic';
import T from 'i18n-react';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';

import {
	CookieConsentBanner,
	CookieConsentContext,
	Theme,
	withCookieConsentProvider,
} from '@motorway/motorway-component-library';

import SitewideFooter from 'Components/SitewideFooter/SitewideFooter';
import texts from 'Components/texts.json';
import { withFeaturesContext } from 'Context/FeaturesContext/FeaturesContext';
import { SiteNavigation } from 'Storybook/local/SiteNavigation';
import { APP_THEME, PAYMENT_MFA_ELEMENT_ID } from 'Utilities/consts';
import { getSlug, isTestEnv } from 'Utilities/helpers';
import { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import proPropTypes from 'Utilities/proPropTypes';

const SitewideBanner = dynamic(() =>
	import(/* webpackChunkName: "sitewide_banner" */ '../SitewideBanner/SitewideBanner'),
);
const PageComposerIE11Banner = dynamic(() =>
	import(/* webpackChunkName: "page_composer_ie11_banner" */ './PageComposerIE11Banner/PageComposerIE11Banner'),
);

T.setTexts(texts);

class PageComposer extends Component {
	componentDidMount() {
		const { router } = this.props;
		router.events.on('routeChangeStart', this.listenToRoutes);
	}

	componentWillUnmount() {
		const { router } = this.props;
		router.events.off('routeChangeStart', this.listenToRoutes);
	}

	listenToRoutes = () => {
		const [, writeAcceptedToCookie] = this.context;

		// hide coookie consent banner, when user starts navigating pages
		if (!isTestEnv) {
			writeAcceptedToCookie();
		}
	};

	render() {
		const {
			children,
			features = {},
			fixedBottomBarClassName,
			router: { asPath },
			showFixedBottomBar,
			showSitewideFooter,
		} = this.props;

		const slug = getSlug(asPath);

		const hasPaymentsFeatureEnabled = features[FEATURES.paymentsMVP];

		const Banner = <SitewideBanner key="SitewideBanner" />;

		return (
			<Theme doNotAlterChildren theme={APP_THEME}>
				{isChromatic() || isTestEnv ? null : Banner}

				<div className="ie11-banner-container-fix">
					{/*
            Container div is required to fix an inconsistent rendering issue on IE11.
            See more details here - https://github.com/motorway/motorway-dealer-webapp/pull/1120#issuecomment-967094427
          */}
					<PageComposerIE11Banner />
				</div>

				<SiteNavigation />

				<div data-slug={slug} id="main">
					{children}
				</div>

				{showSitewideFooter && (
					<SitewideFooter beforeFixedBottomBar={showFixedBottomBar} fixedBottomBarClassName={fixedBottomBarClassName} />
				)}
				{hasPaymentsFeatureEnabled && <div id={PAYMENT_MFA_ELEMENT_ID} />}

				{isChromatic() ? null : <CookieConsentBanner anchorUrl="/cookies" appTitle="Motorway" />}
			</Theme>
		);
	}
}

PageComposer.contextType = CookieConsentContext;

PageComposer.propTypes = {
	children: PropTypes.node.isRequired,
	features: PropTypes.object,
	fixedBottomBarClassName: proPropTypes.className,
	pageSlug: PropTypes.string,
	router: PropTypes.object.isRequired,
	showFixedBottomBar: PropTypes.bool,
	showSitewideFooter: PropTypes.bool,
};

PageComposer.defaultProps = {
	features: {},
	pageSlug: '',
	showFixedBottomBar: false,
	showSitewideFooter: true,
};

export default withCookieConsentProvider(withFeaturesContext(withRouter(PageComposer)));
