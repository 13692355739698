import { useRouter } from 'next/router';

import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import useTradePermission from 'Utilities/hooks/useTradePermission';
import ROUTES from 'Utilities/routes';

type UseTradeResponse = {
	isTradeListingsEnabled: boolean;
	onTradeListingsClick: () => void;
};

export const useTrade = (): UseTradeResponse => {
	const isTradeNavigationEnabled = useFeatureToggle(FEATURES.tradeNavigation);
	const hasTradePermission = useTradePermission();
	const { push } = useRouter();

	const isTradeListingsEnabled = isTradeNavigationEnabled && hasTradePermission;
	const onTradeListingsClick = () => push(ROUTES.TRADE.href);

	return {
		isTradeListingsEnabled,
		onTradeListingsClick,
	};
};
