import React, { forwardRef, HTMLProps, PropsWithChildren, ReactNode } from 'react';
import cx from 'classnames';

import styles from './Content.module.scss';

type ContentProps = PropsWithChildren<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- the original motorway-storybook-cra component accepts arbitrary props and spreads them on the container element
	[key: string]: any;
	center?: boolean;
	className?: string;
	element?: React.ElementType;
	wrapper?: HTMLProps<HTMLDivElement>;
	wrapperContentPost?: ReactNode;
	wrapperContentPre?: ReactNode;
}>;

const Content = forwardRef<unknown, ContentProps>(
	(
		{ center = false, children, className, element = 'div', wrapper, wrapperContentPost, wrapperContentPre, ...props },
		ref,
	) => {
		const ContainerElement = element;

		let content = (
			<ContainerElement
				ref={ref}
				className={cx(styles.content, className, {
					[styles.center]: center,
				})}
				{...props}
			>
				{children}
			</ContainerElement>
		);

		if (wrapper) {
			content = (
				<div {...wrapper}>
					{wrapperContentPre}
					{content}
					{wrapperContentPost}
				</div>
			);
		}

		return content;
	},
);

export default Content;
