export default {
	navMenuItemsBidsAndOffers: {
		active: 'Active',
		didntWin: "Didn't win",
		underOffer: 'Under offer',
	},
	navMenuItemsProducts: {
		motorwayMove: 'Motorway Move',
		motorwayPay: 'Motorway Pay',
		motorwayTrade: 'Motorway Trade',
	},
	navMenuItemsPurchases: {
		cancelled: 'Cancelled',
		complete: 'Complete',
	},
	navMenuMockItemLabels: {
		active: 'Active',
		button: 'Button',
		notActive: 'Not Active',
		tyreProblem: 'Tyre Problem',
	},
};
