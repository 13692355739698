import React, { Ref } from 'react';

import { CrossIcon, MenuIcon } from '@motorway/mw-highway-code';

import { ExtendedThemeVariants } from 'Storybook/config/types';
import { RoundButton } from 'Storybook/core/RoundButton';
import { RoundButtonPropsBase } from 'Storybook/core/RoundButton/RoundButton.types';
import { Variant } from 'Types/variant';

import { getAriaLabel } from './MenuToggle.helper';

export type MenuToggleProps = RoundButtonPropsBase & {
	isOpen?: boolean;
	ref?: Ref<HTMLButtonElement>;
};

const handleVariant = (isOpen: boolean, variant?: string): ExtendedThemeVariants => {
	if (variant === Variant.PRIMARY) {
		return variant;
	}

	return !isOpen ? Variant.SECONDARY : Variant.TRANSPARENT;
};

const MenuToggle = React.forwardRef<HTMLButtonElement, MenuToggleProps>(
	({ cypressId = 'menuToggle', isOpen = false, onClick, variant, ...props }, forwardRef) => (
		<RoundButton
			ref={forwardRef}
			aria-label={getAriaLabel(isOpen)}
			cypressId={cypressId}
			icon={!isOpen ? MenuIcon : CrossIcon}
			onClick={onClick}
			variant={handleVariant(isOpen, variant)}
			{...props}
		/>
	),
);

export default MenuToggle;
