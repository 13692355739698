import { OtpAction } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import axios from 'Services/axios';
import { isomorphicApiV2 } from 'Services/motorway/apiClient';
import { NOT_IMPLEMENTED_RESPONSE } from 'Services/motorway/apiClient.responses';
import { ApiResponseForJsend, ApiServiceFunction } from 'Services/motorway/apiClient.types';
import { API_ROUTES } from 'Utilities/routes';

export const confirmOTPPhoneNumber: ApiServiceFunction<
	Partial<{ otpAction: OtpAction; verificationCode: string }>,
	ApiResponseForJsend<NoParams, { error: 'fail' }>
> = async ({ config, ctx, params }) =>
	isomorphicApiV2(ctx, {
		browser: async () => {
			return axios.post(`${API_ROUTES.PAYMENTS}/otp/confirm-otp-phone-number`, params, config);
		},
		server: async () => {
			return NOT_IMPLEMENTED_RESPONSE;
		},
	});
