import React from 'react';
import cx from 'classnames';

import { Variant } from 'Types/variant';
import { applyCypressData } from 'Utilities/helpers';

import { RoundButtonProps } from './RoundButton.types';

import styles from './RoundButton.module.scss';

const RoundButton = React.forwardRef<HTMLButtonElement, RoundButtonProps>(
	({ cypressId = 'round-button', icon: Icon, onClick, text, variant = Variant.PRIMARY, ...props }, ref) => (
		<button
			{...applyCypressData(cypressId)}
			ref={ref}
			className={cx(styles.roundButton, styles[variant])}
			onClick={onClick}
			{...props}
			type="button"
		>
			{text && <span aria-hidden="true">{text.slice(0, 2)}</span>}
			{Icon && <Icon aria-hidden="true" data-testid="button-icon" size="small" />}
		</button>
	),
);

export default RoundButton;
