import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { NextRouter } from 'next/router';

import { useBreakpoint } from 'Context/BreakpointsContext/BreakpointsContext';
import Drawer from 'Storybook/core/Drawer/Drawer';
import MenuToggle from 'Storybook/peripheral/MenuToggle/MenuToggle';
import { Variant } from 'Types/variant';

export type NavDrawerProps = {
	children: ReactNode;
	router: NextRouter;
};

const NavDrawer = ({ children, router }: NavDrawerProps): ReactElement => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const triggerRef = useRef<HTMLButtonElement>(null);

	const {
		maxWidth: { breakpointMobile: isMobile },
	} = useBreakpoint();

	useEffect(() => {
		const handleRouteChange = () => {
			if (isOpen) setIsOpen(false);
		};

		router.events.on('routeChangeStart', handleRouteChange);
		router.events.on('hashChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
			router.events.off('hashChangeStart', handleRouteChange);
		};
	}, [router, setIsOpen, isOpen]);

	return (
		<>
			<MenuToggle ref={triggerRef} isOpen={false} onClick={() => setIsOpen(true)} />
			<Drawer
				fullHeightContent
				data-testid="nav-drawer-test"
				direction="right"
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				size={isMobile ? 'full' : 'small'}
				triggerRef={triggerRef}
				variant={Variant.SECONDARY}
			>
				{children}
			</Drawer>
		</>
	);
};

export default NavDrawer;
