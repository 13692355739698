import { ReactNode } from 'react';

import { INITIALS_PLACEHOLDER, NAME_PLACEHOLDER } from './Avatar.const';

export const sanitizeName = (str?: string): string =>
	str
		? str
				.replace(
					// eslint-disable-next-line regexp/no-unused-capturing-group
					/([\u2700-\u27BF\uE000-\uF8FF]|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])\uFE0F?)/g, // used to remove emojis from name
					'',
				)
				.replace(/\s+/g, ' ')
				.trim()
		: '';

export const parseInitials = (fullName?: ReactNode): string => {
	if (typeof fullName === 'string' && fullName?.trim().length) {
		const names = sanitizeName(fullName).split(' ');

		const firstInitial = names[0]?.[0].toUpperCase() || '';
		const lastInitial = names.length - 1 !== 0 ? names[names.length - 1]?.[0].toUpperCase() || '' : '';

		return firstInitial + lastInitial;
	}

	return INITIALS_PLACEHOLDER;
};

export const parseName = (fullName?: string): string => {
	const sanitizedName = sanitizeName(fullName);
	return sanitizedName.length ? sanitizedName : NAME_PLACEHOLDER;
};
