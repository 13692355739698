import { GeneratePathResultPrimitiveTypes, GeneratePathURLParamsShape } from './Wizard.types';

/* eslint-disable regexp/no-useless-assertions */
export const ROUTING_REGEX = {
	CATCH_ALL: /(?:\[{2}\.{3}(\w+)\]{2}|\[\.{3}(\w+)\])(?!\])/g, // /vehicles/[...a]/d, /vehicles/[[...a]]/d
	CATCH_ALL_END_OF_PATH: /(?:\[{2}\.{3}(\w+)\]{2}|\[\.{3}(\w+)\])(?!\])$/g, // /vehicles/[...a], /vehicles/[[...a]]
	MULTIPLE_SLASHES: /\/{2,}/g, // /vehicles/// => /vehicles/
	OPTIONAL_PARAM: /\[{2}(\w+)\]{2}(?=[^\]]|$)/g, // /vehicles/[[optional_param]]
	OPTIONAL_PARAM_CATCH_ALL: /\[{2}\.{3}(\w+)\]{2}(?=[^\]]|$)/g, // /vehicles/[[...optional_param_catch_all]]
	PARAM: /\[(\w+)\](?=[^\]]|$)/g, // /vehicles/[param]
	PARAM_CATCH_ALL: /\[\.{3}(\w+)\](?=[^\]]|$)/g, // /vehicles/[...param_catch_all]
	TRAILING_SLASH: /(?!^)\/$/g, // /vehicles/ => /vehicles
};

const getMatchedParams = <R>(
	path: string,
	mapper: (value: RegExpMatchArray, index: number, array: RegExpMatchArray[]) => R,
) => {
	const paramsMatch = [...path.matchAll(ROUTING_REGEX.PARAM)].map(mapper);
	const optionalParamsMatch = [...path.matchAll(ROUTING_REGEX.OPTIONAL_PARAM)].map(mapper);
	const paramsCatchAllMatch = [...path.matchAll(ROUTING_REGEX.PARAM_CATCH_ALL)].map(mapper);
	const optionalParamsCatchAllMatch = [...path.matchAll(ROUTING_REGEX.OPTIONAL_PARAM_CATCH_ALL)].map(mapper);

	return {
		optionalParamsCatchAllMatch,
		optionalParamsMatch,
		paramsCatchAllMatch,
		paramsMatch,
	};
};

const validatePath = (path: string) => {
	const matchParamsArray = Object.values(getMatchedParams(path, ([key]) => key)).flat();
	const repeatedParam = matchParamsArray.find((k) => matchParamsArray.lastIndexOf(k) !== matchParamsArray.indexOf(k));

	if (repeatedParam) {
		throw new Error(`Invalid path. Path cannot have more than 1 '${repeatedParam}' param!`);
	}

	const catchAllMatch = path.match(ROUTING_REGEX.CATCH_ALL);

	if (catchAllMatch && catchAllMatch?.length > 1) {
		throw new Error('Invalid path. Path cannot have more than 1 catch-all param!');
	}

	if (path.match(ROUTING_REGEX.CATCH_ALL) && !path.match(ROUTING_REGEX.CATCH_ALL_END_OF_PATH)) {
		throw new Error('Invalid path. Catch-all param is not ending the path!');
	}
};

export function generatePath<R extends string>(path: string, params: GeneratePathURLParamsShape): R {
	validatePath(path);

	// we could utilise TS `is` type guard here, but it would require to define a custom type guard for each param type
	// and it would be an overkill for this case, as we have runtime checks for param types.
	//
	// We could also use Zod, but this would mean we pull external dependency for such a small and well tested use case.
	const result = path
		.replace(ROUTING_REGEX.PARAM, (_, key) => {
			if (!params?.[key]) {
				throw new Error(`Missing [${key}] key`);
			}

			if (!['string', 'number'].includes(typeof params[key])) {
				throw new Error(`Expected [${key}] to be 'string' or 'number'. Received '${typeof params[key]}'.`);
			}

			return (params[key] as GeneratePathResultPrimitiveTypes).toString();
		})
		.replace(ROUTING_REGEX.PARAM_CATCH_ALL, (_, key) => {
			if (!params?.[key]) {
				throw new Error(`Missing [${key}] key`);
			}

			if (!Array.isArray(params[key])) {
				throw new Error(`Expected 'array' value for catch-all param [...${key}]. Received '${typeof params[key]}'.`);
			}

			(params[key] as Array<GeneratePathResultPrimitiveTypes> | Array<Array<GeneratePathResultPrimitiveTypes>>).forEach(
				(v, i) => {
					if (!['string', 'number'].includes(typeof v)) {
						throw new Error(
							`Expected [...${key}] value on index ${i} to be 'string' or 'number'. Received '${typeof v}'.`,
						);
					}
				},
			);

			return (params[key] as Array<GeneratePathResultPrimitiveTypes>).join('/');
		})
		.replace(ROUTING_REGEX.OPTIONAL_PARAM, (_, key) => {
			if (!params?.[key]) {
				return '';
			}

			if (!['string', 'number'].includes(typeof params[key])) {
				throw new Error(`Expected [[${key}]] to be 'string' or 'number'. Received '${typeof params[key]}'.`);
			}

			return (params[key] as GeneratePathResultPrimitiveTypes).toString();
		})
		.replace(ROUTING_REGEX.OPTIONAL_PARAM_CATCH_ALL, (_, key) => {
			if (!params?.[key]) {
				return '';
			}

			if (!Array.isArray(params[key])) {
				throw new Error(`Expected 'array' value for catch-all param [[...${key}]]. Received '${typeof params[key]}'.`);
			}

			(params[key] as Array<GeneratePathResultPrimitiveTypes> | Array<Array<GeneratePathResultPrimitiveTypes>>).forEach(
				(v, i) => {
					if (!['string', 'number'].includes(typeof v)) {
						throw new Error(
							`Expected [[...${key}]] value on index ${i} to be 'string' or 'number'. Received '${typeof v}'.`,
						);
					}
				},
			);

			return (params[key] as Array<GeneratePathResultPrimitiveTypes>).join('/');
		})
		.replace(ROUTING_REGEX.MULTIPLE_SLASHES, '/')
		.replace(ROUTING_REGEX.TRAILING_SLASH, '');

	return result as R;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const matchPath = (page: string, { path }: { path: string }) => {
	// if validatePath will throw, it means path isn't valid;
	try {
		validatePath(path);
	} catch (e) {
		return null;
	}

	const params: Record<string, string | string[]> = {};

	if (page === path) {
		return {
			params,
			path,
			url: generatePath(path, params),
		};
	}

	const pageAtoms = page.split('/');
	const pathAtoms = path.split('/');

	const { optionalParamsCatchAllMatch, optionalParamsMatch, paramsCatchAllMatch, paramsMatch } = getMatchedParams(
		path,
		([match, key]) => ({ key, match }),
	);

	[...paramsMatch, ...optionalParamsMatch].forEach((p) => {
		const paramIndex = pathAtoms.findIndex((v) => v === p.match);
		const paramValue = pageAtoms[paramIndex];

		if (paramValue) {
			params[p.key] = paramValue;
		}
	});

	[...paramsCatchAllMatch, ...optionalParamsCatchAllMatch].forEach((p) => {
		const paramIndex = pathAtoms.findIndex((v) => v === p.match);
		const paramValue = paramIndex + 1 ? pageAtoms.slice(paramIndex) : [];

		if (paramValue.length) {
			params[p.key] = paramValue;
		}
	});

	let resolvedPath: string;

	// if generatePath will throw, it means path isn't a match;
	try {
		resolvedPath = generatePath(path, params);
	} catch (e) {
		return null;
	}

	if (page === resolvedPath) {
		return {
			params,
			path,
			url: resolvedPath,
		};
	}

	return null;
};
