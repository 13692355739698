import axios from 'Services/axios';
import { isomorphicApiV2 } from 'Services/motorway/apiClient';
import { NO_DEALER_ID_PROVIDED_RESPONSE, NOT_IMPLEMENTED_RESPONSE } from 'Services/motorway/apiClient.responses';
import { ApiResponseForJsend, ApiServiceFunction } from 'Services/motorway/apiClient.types';
import * as KycService from 'Services/motorway/services/kyc/kyc';
import { KycCompanyDetails, KycStatusRequest, KycStatusResponse } from 'Services/motorway/services/kyc/kyc.types';
import { API_ROUTES } from 'Utilities/routes';

export const getKycCompanyDetails: ApiServiceFunction<NoParams, ApiResponseForJsend<KycCompanyDetails>> = async ({
	config,
	ctx,
}) =>
	isomorphicApiV2(ctx, {
		browser: async () => {
			return axios.get(`${API_ROUTES.KYC}/company-details`);
		},
		server: async () => {
			const dealerId = ctx?.req?.user?.dealerId;
			if (!dealerId) return NO_DEALER_ID_PROVIDED_RESPONSE;

			return KycService.getKycCompanyDetails({
				config,
				environment: 'server',
				params: {
					dealerId,
				},
			});
		},
	});

export const updateKycCompanyDetails: ApiServiceFunction<
	Partial<KycCompanyDetails>,
	ApiResponseForJsend<KycCompanyDetails>
> = async ({ config, ctx, params }) =>
	isomorphicApiV2(ctx, {
		browser: async () => {
			return axios.post(`${API_ROUTES.KYC}/company-details`, params, config);
		},
		server: async () => {
			return NOT_IMPLEMENTED_RESPONSE;
		},
	});

export const getKycStatus: ApiServiceFunction<NoParams, ApiResponseForJsend<KycStatusResponse>> = async ({
	config,
	ctx,
}) =>
	isomorphicApiV2(ctx, {
		browser: async () => {
			return axios.get(`${API_ROUTES.KYC}/status`);
		},
		server: async () => {
			const dealerId = ctx?.req?.user?.dealerId;
			if (!dealerId) return NO_DEALER_ID_PROVIDED_RESPONSE;

			return KycService.getKycStatus({
				config,
				environment: 'server',
				params: {
					dealerId,
				},
			});
		},
	});

export const updateKycStatus: ApiServiceFunction<
	Partial<KycStatusRequest>,
	ApiResponseForJsend<NoParams, { error: 'fail' }>
> = async ({ config, ctx, params }) =>
	isomorphicApiV2(ctx, {
		browser: async () => {
			return axios.patch(`${API_ROUTES.KYC}/status`, params, config);
		},
		server: async () => {
			return NOT_IMPLEMENTED_RESPONSE;
		},
	});
