import { useSaleTimesFromStore } from 'Stores/SaleTimesStore/SaleTimesStore';
import { SaleTimes } from 'Types/saleTimes';
import { isSaleToday } from 'Utilities/helpers/isSaleLaterToday';

const useSaleTimes = (): SaleTimes => {
	const saleTimes = useSaleTimesFromStore();

	const {
		isSaleActive = false,
		millisecondsRemaining = 0,
		nextSaleStartsAt = null,
		saleClosingHour = null,
		saleClosingMinute = null,
		todaysSaleEndsAt = null,
		todaysSaleStartedAt = null,
	} = saleTimes || {};

	const isSaleLaterToday = isSaleToday(nextSaleStartsAt);

	return {
		hasReceivedSaleTimes: Boolean(saleTimes),
		isSaleActive,
		isSaleLaterToday,
		millisecondsRemaining,
		nextSaleStartsAt,
		saleClosingHour,
		saleClosingMinute,
		todaysSaleEndsAt,
		todaysSaleStartedAt,
	};
};

export default useSaleTimes;
