import { useCallback, useMemo } from 'react';
import { simpleIdGenerator } from '@THC/utils/simpleIdGenerator';

import { errorInfoboxSetError } from './emitter/ErrorInfoboxEmitter.events';
import { ErrorInfobox } from './ErrorInfobox';
import { ErrorInfoboxEvent, ErrorInfoboxProps } from './ErrorInfobox.types';

const errorInfoboxIdGenerator = simpleIdGenerator('dwa-error-infobox');

export const getErrorInfoboxId = (): string => {
	const errorInfoboxId = errorInfoboxIdGenerator.next().value;

	return errorInfoboxId;
};

export const useErrorInfoboxMessageId = (): string => {
	const errorInfoboxId = useMemo(getErrorInfoboxId, []);

	return errorInfoboxId;
};

export const useErrorInfobox = (
	props?: Omit<ErrorInfoboxProps, 'id'> & { id?: string },
): [ReturnType<typeof ErrorInfobox>, (nextProps: ErrorInfoboxEvent) => void, { id: string }] => {
	const generatedId = useErrorInfoboxMessageId();

	const id = props?.id || generatedId;

	const setError = useCallback(
		(nextProps: ErrorInfoboxEvent) => {
			errorInfoboxSetError({ id, props: nextProps });
		},
		[id],
	);

	const error = <ErrorInfobox id={id} {...props} />;

	return [error, setError, { id }];
};
