import React from 'react';
import cx from 'classnames';
import Link from 'next/link';

import { LogoMotorway, LogoMotorwayXmas } from '@motorway/motorway-storybook-cra';

import cypressIds from 'Components/cypress_ids.json';
import { applyCypressData } from 'Utilities/helpers';
import { useVehicleListButtonConfigWithUnauthedFallback } from 'Utilities/hooks/vehicleList/useVehicleListButtonConfig';

import styles from './SitewideHeaderLogoLink.module.scss';

interface SitewideHeaderLogoLinkProps {
	shouldDisplayXmasLogo: boolean;
}

const SitewideHeaderLogoLink: React.FC<SitewideHeaderLogoLinkProps> = ({ shouldDisplayXmasLogo = false }) => {
	const { as, href, onClick, replace } = useVehicleListButtonConfigWithUnauthedFallback({
		unauthorizedFallback: 'index',
	});

	return (
		<Link as={as} href={href} replace={replace}>
			<button
				className={cx('mw-ignore', 'mw-i', styles.logoRegular)}
				onClick={onClick}
				title="Motorway"
				type="button"
				{...applyCypressData(cypressIds.buttons.siteNavigationLogo)}
			>
				{shouldDisplayXmasLogo ? (
					<LogoMotorwayXmas className={styles.logo} />
				) : (
					<LogoMotorway className={styles.logo} />
				)}
			</button>
		</Link>
	);
};

export default SitewideHeaderLogoLink;
