import { useListType } from 'Stores/ListStore/ListStore';
import ROUTES from 'Utilities/routes';

import useIsAuth from '../useIsAuth';
import { useIsPageActive } from '../useIsPageActive';
import useSaleTimes from '../useSaleTimes';

import { useAdjustedListType } from './useAdjustedListType';
import {
	getFallbackButtonConfig,
	getVehicleListQuery,
	isForceFormatBasedOnTime,
	useOnBrowseVehiclesClick,
} from './useVehicleListButtonConfig.helpers';
import {
	ButtonConfig,
	UseVehicleListButtonConfigProps,
	UseVehicleListButtonConfigWithUnauthedFallbackProps,
} from './useVehicleListButtonConfig.types';

const useVehicleListButtonConfig = ({
	clearListCache = true,
	refreshVehicleList = true,
}: UseVehicleListButtonConfigProps = {}): ButtonConfig => {
	// TODO: - refactor all the tests for this hook as it relies of logic embedded on in dependent functions/hooks.
	const listType = useListType();
	const { hasReceivedSaleTimes, isSaleActive } = useSaleTimes();

	const forceFormatBasedOnTime = isForceFormatBasedOnTime({ isSaleActive, listType });
	const adjustedListType = useAdjustedListType({ forceFormatBasedOnTime });

	const isVehicleListPageActive = useIsPageActive(ROUTES.VEHICLE_LIST.href);
	const vehicleListQuery = getVehicleListQuery({
		adjustedListType,
		hasReceivedSaleTimes,
	});

	const onClick = useOnBrowseVehiclesClick({
		adjustedListType,
		clearListCache,
		listType,
		refreshVehicleList,
		vehicleListQuery,
	});

	return {
		as: `${ROUTES.VEHICLE_LIST.as()}${vehicleListQuery}`,
		href: `${ROUTES.VEHICLE_LIST.href}${vehicleListQuery}`,
		onClick,
		replace: isVehicleListPageActive,
	};
};

export const useVehicleListButtonConfigWithUnauthedFallback = ({
	unauthorizedFallback,
}: UseVehicleListButtonConfigWithUnauthedFallbackProps): ButtonConfig => {
	const isAuth = useIsAuth();
	const vehicleListButtonConfig = useVehicleListButtonConfig();
	return isAuth ? vehicleListButtonConfig : getFallbackButtonConfig(unauthorizedFallback);
};

export default useVehicleListButtonConfig;
