import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useResetTimeId } from 'Stores/BidCountSortStore/BidCountSortStore';
import { useResetListPosition } from 'Stores/VehicleListPositionStore/VehicleListPositionStore';

import { VEHICLE_LIST_QUERY_KEY } from '../useFetchVehicleList/useFetchVehicleList';

interface ClearVehicleListCache {
	clearAllVehicleListCache: Noop;
	clearInactiveVehicleListCache: Noop;
}

export const useClearVehicleListCache = (): ClearVehicleListCache => {
	const resetTimeId = useResetTimeId();
	const queryClient = useQueryClient();
	const resetListPosition = useResetListPosition();

	const clearInactiveVehicleListCache = useCallback(() => {
		queryClient.removeQueries({ queryKey: [VEHICLE_LIST_QUERY_KEY], type: 'inactive' });
	}, [queryClient]);

	const clearAllVehicleListCache = useCallback(() => {
		resetTimeId();
		resetListPosition();
		queryClient.resetQueries({ exact: false, queryKey: [VEHICLE_LIST_QUERY_KEY] });
	}, [queryClient, resetListPosition, resetTimeId]);

	return {
		clearAllVehicleListCache,
		clearInactiveVehicleListCache,
	};
};
