export default {
	menuLabels: {
		bidsAndOffers: 'Bids & offers',
		howItWorks: 'How it works',
		insights: 'Insights',
		marketplace: 'Marketplace',
		products: 'Products',
		purchases: 'Purchases',
		signIn: 'Sign in',
		signUpNow: 'Sign up now',
		trade: 'Trade listings',
	},
};
