import { useEffect, useRef, useState } from 'react';

interface ScrollData {
	isScrollingUp: boolean;
	isTopOfPage: boolean;
}

const useScrollData = (): ScrollData => {
	const [isTopOfPage, setIsTopOfPage] = useState<boolean>(true);
	const prevScrollY = useRef<number>(0);
	const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false);

	useEffect(() => {
		let animationFrameId: number;

		const handleScroll = () => {
			const currentScrollY = window.scrollY;

			if (animationFrameId) {
				cancelAnimationFrame(animationFrameId);
			}

			animationFrameId = requestAnimationFrame(() => {
				const isCurrentlyTop = currentScrollY === 0;
				const isScrollingUpNow = prevScrollY.current > currentScrollY;

				if (isScrollingUp !== isScrollingUpNow) {
					setIsScrollingUp(isScrollingUpNow);
				}

				if (isCurrentlyTop !== isTopOfPage) {
					setIsTopOfPage(isCurrentlyTop);
				}
			});

			prevScrollY.current = currentScrollY;
		};

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);

			if (animationFrameId) {
				cancelAnimationFrame(animationFrameId);
			}
		};
	}, [isScrollingUp, isTopOfPage]);

	return { isScrollingUp, isTopOfPage };
};

export { useScrollData };
