import { MDText } from 'i18n-react';

import LocalTexts from './SiteNavigation.texts';

const LocalT = new MDText(LocalTexts);

export const MENU_ITEMS = {
	BIDS_AND_OFFERS: {
		LABEL: LocalT.translate('menuLabels.bidsAndOffers'),
	},
	HOW_IT_WORKS: {
		LABEL: LocalT.translate('menuLabels.howItWorks'),
	},
	INSIGHTS: {
		LABEL: LocalT.translate('menuLabels.insights'),
	},
	MARKETPLACE: {
		LABEL: LocalT.translate('menuLabels.marketplace'),
	},
	PRODUCTS: {
		LABEL: LocalT.translate('menuLabels.products'),
	},
	PURCHASES: {
		LABEL: LocalT.translate('menuLabels.purchases'),
	},
	SIGN_IN: {
		LABEL: LocalT.translate('menuLabels.signIn'),
	},
	SIGN_UP_NOW: {
		LABEL: LocalT.translate('menuLabels.signUpNow'),
	},
	TRADE: {
		LABEL: LocalT.translate('menuLabels.trade'),
	},
};
