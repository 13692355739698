import {
	BagIcon,
	GlitterIcon,
	GraphIcon,
	HelpIcon,
	PaperIcon,
	PriceTagIcon,
	SpannerIcon,
	TagIcon,
	VehicleFrontIcon,
	VehicleTagIcon,
	WalletIcon,
} from '@THC/components/Icons';
import { MDText } from 'i18n-react';
import getConfig from 'next/config';

import cypressIds from 'Components/cypress_ids.json';
import {
	triggerNavigationActiveBidsClickedEvent,
	triggerNavigationBidsClickedEvent,
	triggerNavigationBillingClickedEvent,
	triggerNavigationCancelledPurchasesClickedEvent,
	triggerNavigationCompletedPurchasesClickedEvent,
	triggerNavigationDidntWinBidsClickedEvent,
	triggerNavigationHelpCenterClickedEvent,
	triggerNavigationHowItWorksClickedEvent,
	triggerNavigationInsightsClickedEvent,
	triggerNavigationMarketplaceClickedEvent,
	triggerNavigationMotorwayMoveClickedEvent,
	triggerNavigationMotorwayPayClickedEvent,
	triggerNavigationMotorwayTradeClickedEvent,
	triggerNavigationPricingClickedEvent,
	triggerNavigationPurchasesClickedEvent,
	triggerNavigationTradeClickedEvent,
	triggerNavigationUnderOfferBidsClickedEvent,
	triggerNavigationWalletClickedEvent,
} from 'Services/analytics/events';
import { insightsActiveStateCallback, tradeActiveStateCallback } from 'Storybook/core/Menu/__stories__/helpers/helpers';
import {
	GuestMenuAnalytics,
	triggerGuestSiteNavigationGAEvent,
	triggerUserSiteNavigationGAEvent,
	UserMenuAnalytics,
} from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';
import { AccountCategory, AccountSlugs } from 'Types/account';
import { HorizontalAlignment } from 'Types/generic';
import { LIST_TYPES } from 'Utilities/consts';
import ROUTES from 'Utilities/routes';

import LocalTexts from './NavAccordion.texts';
import { NavAccordionItem, NavAccordionLink, NavAccordionList, NavAccordionRouter } from './NavAccordion.types';

const LocalT = new MDText(LocalTexts);
const { publicRuntimeConfig } = getConfig();
const { HELP_CENTER_URL } = publicRuntimeConfig;

export const isNavAccordionLink = (item: NavAccordionItem): item is NavAccordionLink =>
	item.href !== undefined && item.subItems === undefined;

export const isNavAccordionList = (item: NavAccordionItem): item is NavAccordionList =>
	item.href === undefined && item.subItems !== undefined && !item.hide;

interface GetLoggedInNavAccordionItemsProps {
	marketplaceItem: Pick<NavAccordionLink, 'as' | 'href' | 'onClick'>;
}

export const getLoggedInNavAccordionItems = ({
	marketplaceItem,
}: GetLoggedInNavAccordionItemsProps): NavAccordionItem[] => {
	const items: NavAccordionItem[] = [
		{
			as: marketplaceItem.as,
			cypressId: cypressIds.navAccordion.marketplace,
			getActiveState: ({ pathname }) => pathname === ROUTES.VEHICLE_LIST.href,
			href: marketplaceItem.href,
			icon: VehicleFrontIcon,
			id: 'marketplace',
			name: LocalT.translate('navAccordionItemLabels.marketplace'),
			onClick: (e: Parameters<NonNullable<NavAccordionItem['onClick']>>[0]) => {
				marketplaceItem.onClick?.(e);
				triggerNavigationMarketplaceClickedEvent({ isSaleSlug: marketplaceItem.href.includes(LIST_TYPES.auction) });
				triggerUserSiteNavigationGAEvent(
					marketplaceItem.href.includes(LIST_TYPES.auction) ? 'marketplaceLiveSale' : 'marketplaceBuyItNow',
				);
			},
		},
		{
			cypressId: cypressIds.navAccordion.bidsAndOffers.base,
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href && query?.category === AccountCategory.BIDS,
			icon: TagIcon,
			id: 'bids_offers',
			name: LocalT.translate('navAccordionItemLabels.bidsAndOffers.root'),
			onClick: () => {
				triggerNavigationBidsClickedEvent();
				triggerUserSiteNavigationGAEvent('bidsAndOffers');
			},
			subItems: [
				{
					as: ROUTES.ACCOUNT.as(AccountCategory.BIDS, AccountSlugs.ACTIVE),
					cypressId: cypressIds.navAccordion.bidsAndOffers.active,
					getActiveState: ({ pathname, query }) =>
						pathname === ROUTES.ACCOUNT.href &&
						query?.category === AccountCategory.BIDS &&
						query?.slug === AccountSlugs.ACTIVE,
					href: ROUTES.ACCOUNT.href,
					name: LocalT.translate('navAccordionItemLabels.bidsAndOffers.active'),
					onClick: () => {
						triggerUserSiteNavigationGAEvent('bidsAndOffersActive');
						triggerNavigationActiveBidsClickedEvent();
					},
				},
				{
					as: ROUTES.ACCOUNT.as(AccountCategory.BIDS, AccountSlugs.UNDER_OFFER),
					cypressId: cypressIds.navAccordion.bidsAndOffers.underOffer,
					getActiveState: ({ pathname, query }) =>
						pathname === ROUTES.ACCOUNT.href &&
						query?.category === AccountCategory.BIDS &&
						query?.slug === AccountSlugs.UNDER_OFFER,
					href: ROUTES.ACCOUNT.href,
					name: LocalT.translate('navAccordionItemLabels.bidsAndOffers.underOffer'),
					onClick: () => {
						triggerUserSiteNavigationGAEvent('bidsAndOffersUnderOffer');
						triggerNavigationUnderOfferBidsClickedEvent();
					},
				},
				{
					as: ROUTES.ACCOUNT.as(AccountCategory.BIDS, AccountSlugs.DIDNT_WIN),
					cypressId: cypressIds.navAccordion.bidsAndOffers.didntWin,
					getActiveState: ({ pathname, query }) =>
						pathname === ROUTES.ACCOUNT.href &&
						query?.category === AccountCategory.BIDS &&
						query?.slug === AccountSlugs.DIDNT_WIN,
					href: ROUTES.ACCOUNT.href,
					name: LocalT.translate('navAccordionItemLabels.bidsAndOffers.didntWin'),
					onClick: () => {
						triggerUserSiteNavigationGAEvent('bidsAndOffersDidntWin');
						triggerNavigationDidntWinBidsClickedEvent();
					},
				},
			],
		},
		{
			cypressId: cypressIds.navAccordion.purchases.base,
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href && query?.category === AccountCategory.PURCHASES,
			icon: BagIcon,
			id: 'purchases',
			name: LocalT.translate('navAccordionItemLabels.purchases.root'),
			onClick: () => {
				triggerNavigationPurchasesClickedEvent();
				triggerUserSiteNavigationGAEvent('purchases');
			},
			subItems: [
				{
					as: ROUTES.ACCOUNT.as(AccountCategory.PURCHASES, AccountSlugs.COMPLETE),
					cypressId: cypressIds.navAccordion.purchases.complete,
					getActiveState: ({ pathname, query }) =>
						pathname === ROUTES.ACCOUNT.href &&
						query?.category === AccountCategory.PURCHASES &&
						query?.slug === AccountSlugs.COMPLETE,
					href: ROUTES.ACCOUNT.href,
					name: LocalT.translate('navAccordionItemLabels.purchases.complete'),
					onClick: () => {
						triggerUserSiteNavigationGAEvent('purchasesComplete');
						triggerNavigationCompletedPurchasesClickedEvent();
					},
				},
				{
					as: ROUTES.ACCOUNT.as(AccountCategory.PURCHASES, AccountSlugs.CANCELLED),
					cypressId: cypressIds.navAccordion.purchases.cancelled,
					getActiveState: ({ pathname, query }) =>
						pathname === ROUTES.ACCOUNT.href &&
						query?.category === AccountCategory.PURCHASES &&
						query?.slug === AccountSlugs.CANCELLED,
					href: ROUTES.ACCOUNT.href,
					name: LocalT.translate('navAccordionItemLabels.purchases.cancelled'),
					onClick: () => {
						triggerUserSiteNavigationGAEvent('purchasesCancelled');
						triggerNavigationCancelledPurchasesClickedEvent();
					},
				},
			],
		},
		{
			as: ROUTES.INSIGHTS_LANDING.as(),
			badgeLabel: LocalT.translate('navAccordionItemLabels.insightsBadge'),
			cypressId: cypressIds.navAccordion.insights,
			getActiveState: insightsActiveStateCallback,
			href: ROUTES.INSIGHTS_LANDING.href,
			icon: GraphIcon,
			id: 'insights',
			name: LocalT.translate('navAccordionItemLabels.insights'),
			onClick: () => {
				triggerNavigationInsightsClickedEvent();
				triggerUserSiteNavigationGAEvent('insights');
			},
		},
	];

	return items;
};

interface GetProductNavAccordionItemsProps {
	isTradeExperience?: boolean;
	isTransportExperience?: boolean;
	triggerGAEvent: (
		eventName: keyof typeof UserMenuAnalytics,
	) => void | ((eventName: keyof typeof GuestMenuAnalytics) => void);
}

export const getProductNavAccordionItems = ({
	isTradeExperience,
	isTransportExperience,
	triggerGAEvent,
}: GetProductNavAccordionItemsProps): NavAccordionItem[] => [
	{
		cypressId: cypressIds.navAccordion.products.base,
		getActiveState: ({ pathname }) =>
			([ROUTES.MOTORWAY_MOVE.href, ROUTES.MOTORWAY_PAY.href, ROUTES.MOTORWAY_TRADE.href] as string[]).includes(
				pathname,
			),
		icon: GlitterIcon,
		id: 'products',
		name: LocalT.translate('navAccordionItemLabels.products.root'),
		onClick: () => triggerGAEvent('products'),
		subItems: [
			{
				as: ROUTES.MOTORWAY_PAY.as(),
				cypressId: cypressIds.navAccordion.products.motorwayPay,
				getActiveState: ({ pathname }) => pathname === ROUTES.MOTORWAY_PAY.href,
				href: ROUTES.MOTORWAY_PAY.href,
				name: LocalT.translate('navAccordionItemLabels.products.motorwayPay'),
				onClick: () => {
					triggerGAEvent('motorwayPay');
					triggerNavigationMotorwayPayClickedEvent();
				},
			},
			{
				as: ROUTES.MOTORWAY_MOVE.as(),
				cypressId: cypressIds.navAccordion.products.motorwayMove,
				getActiveState: ({ pathname }) => pathname === ROUTES.MOTORWAY_MOVE.href,
				hide: !isTransportExperience,
				href: ROUTES.MOTORWAY_MOVE.href,
				name: LocalT.translate('navAccordionItemLabels.products.motorwayMove'),
				onClick: () => {
					triggerGAEvent('motorwayMove');
					triggerNavigationMotorwayMoveClickedEvent();
				},
			},
			{
				as: ROUTES.MOTORWAY_TRADE.as(),
				cypressId: cypressIds.navAccordion.products.motorwayTrade,
				extensionPosition: HorizontalAlignment.Right,
				getActiveState: ({ pathname }) => pathname === ROUTES.MOTORWAY_TRADE.href,
				hide: !isTradeExperience,
				href: ROUTES.MOTORWAY_TRADE.href,
				name: LocalT.translate('navAccordionItemLabels.products.motorwayTrade'),
				onClick: (): void => {
					triggerGAEvent('motorwayTrade');
					triggerNavigationMotorwayTradeClickedEvent();
				},
			},
		],
	},
];

interface GetLoggedOutNavAccordionItemsProps {
	isTradeExperience?: boolean;
	isTransportExperience?: boolean;
}
export const getLoggedOutNavAccordionItems = ({
	isTradeExperience,
	isTransportExperience,
}: GetLoggedOutNavAccordionItemsProps): NavAccordionItem[] => [
	{
		as: ROUTES.HOW_IT_WORKS.as(),
		cypressId: cypressIds.guestMenuLinks.howItWorks,
		getActiveState: ({ pathname }) => pathname === ROUTES.HOW_IT_WORKS.href,
		href: ROUTES.HOW_IT_WORKS.href,
		icon: SpannerIcon,
		id: 'how_it_works',
		name: LocalT.translate('navAccordionItemLabelsLoggedOut.howItWorks'),
		onClick: () => {
			triggerGuestSiteNavigationGAEvent('howItWorks');
			triggerNavigationHowItWorksClickedEvent();
		},
	},
	...getProductNavAccordionItems({
		isTradeExperience,
		isTransportExperience,
		triggerGAEvent: triggerGuestSiteNavigationGAEvent,
	}),
];

interface GetOtherNavAccordionItemsArgs {
	isBillingEnabled: boolean;
	isWalletEnabled: boolean;
	onBillingClick: () => void | Promise<void>;
	onWalletClick: () => void;
	showTradeNavigationItem: boolean;
}

export const getOtherNavAccordionItems = ({
	isBillingEnabled,
	isWalletEnabled,
	onBillingClick,
	onWalletClick,
	showTradeNavigationItem,
}: GetOtherNavAccordionItemsArgs): NavAccordionItem[] => {
	return [
		...(showTradeNavigationItem
			? [
					{
						as: ROUTES.TRADE.as(),
						cypressId: cypressIds.navAccordion.trade,
						getActiveState: tradeActiveStateCallback,
						href: ROUTES.TRADE.href,
						icon: VehicleTagIcon,
						id: 'trade',
						name: LocalT.translate('navAccordionItemLabels.trade'),
						onClick: () => {
							triggerUserSiteNavigationGAEvent('trade');
							triggerNavigationTradeClickedEvent();
						},
					},
			  ]
			: []),
		...(isWalletEnabled
			? [
					{
						as: '#',
						cypressId: cypressIds.buttons.wallet,
						getActiveState: ({ pathname }: NavAccordionRouter) => pathname === ROUTES.DEALER_WALLET.as(),
						href: '#',
						icon: WalletIcon,
						id: 'wallet',
						name: `${LocalT.translate('navAccordionItemLabels.other.wallet')}`,
						onClick: (e: Parameters<NonNullable<NavAccordionItem['onClick']>>[0]) => {
							e.preventDefault();
							triggerUserSiteNavigationGAEvent('wallet');
							triggerNavigationWalletClickedEvent();
							return onWalletClick();
						},
					},
			  ]
			: []),
		...(isBillingEnabled
			? [
					{
						as: '#',
						cypressId: cypressIds.buttons.billing,
						getActiveState: () => false,
						href: '#',
						icon: PaperIcon,
						id: 'billing',
						name: `${LocalT.translate('navAccordionItemLabels.other.billing')}`,
						onClick: (e: Parameters<NonNullable<NavAccordionItem['onClick']>>[0]) => {
							e.preventDefault();
							triggerUserSiteNavigationGAEvent('billing');
							triggerNavigationBillingClickedEvent();
							return onBillingClick();
						},
					},
			  ]
			: []),
		{
			as: '#',
			cypressId: cypressIds.buttons.helpCenter,
			getActiveState: () => false,
			href: '#',
			icon: () => HelpIcon({ filled: false }),
			id: 'help-center',
			name: `${LocalT.translate('navAccordionItemLabels.other.helpCenter')}`,
			onClick: (e: Parameters<NonNullable<NavAccordionItem['onClick']>>[0]) => {
				e.preventDefault();
				window.open(HELP_CENTER_URL, '_blank');
				triggerNavigationHelpCenterClickedEvent();
				triggerUserSiteNavigationGAEvent('helpCenter');
			},
		},
		{
			as: ROUTES.PRICING.as(),
			cypressId: cypressIds.buttons.pricing,
			getActiveState: ({ pathname }: NavAccordionRouter) => pathname === ROUTES.PRICING.as(),
			href: ROUTES.PRICING.href,
			icon: PriceTagIcon,
			id: 'pricing',
			name: `${LocalT.translate('navAccordionItemLabels.other.pricing')}`,
			onClick: () => {
				triggerUserSiteNavigationGAEvent('pricing');
				triggerNavigationPricingClickedEvent();
			},
		},
	];
};
