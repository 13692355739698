import { create } from 'zustand';

import { KycStore } from './KycStore.types';

export const useKycStore = create<KycStore>((set, get) => ({
	getKycStatus: () => {
		const { kycStatus } = get();
		return kycStatus;
	},
	getLastFetchedAt: () => {
		const { lastFetchedAt } = get();
		return lastFetchedAt;
	},
	kycStatus: undefined,
	lastFetchedAt: undefined,
	setState: (state) => {
		set(state);
	},
}));
