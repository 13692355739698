import React, { ReactElement } from 'react';
import cx from 'classnames';

import { SeparatorProps } from './Separator.types';

import styles from './Separator.module.scss';

const Separator = ({ height = 'default', width = 'small', ...props }: SeparatorProps): ReactElement => (
	<div
		className={cx(styles.separator, styles[width], { [styles[height]]: height !== 'default' })}
		data-testid="menu-separator"
		{...props}
	/>
);

export default Separator;
