import React from 'react';
import { filterDataProps } from '@THC/utils/helpers';

import { craftMoneyFigure } from './craftMoneyFigure';
import { MoneyFigureProps } from './MoneyFigure.types';

import styles from './MoneyFigure.module.scss';

export const MoneyFigure = (params: MoneyFigureProps): React.ReactElement => {
	const { variant = 'heading6', ...rest } = params;
	const { classNames, formattedBaseValue, formattedFractionValue } = craftMoneyFigure({ variant, ...rest });

	return (
		<span className={styles.moneyFigure} {...filterDataProps(rest)}>
			<strong className={classNames.base}>{formattedBaseValue}</strong>
			<sup className={classNames.fraction}>{formattedFractionValue}</sup>
		</span>
	);
};
