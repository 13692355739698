import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const { SITEWIDE_FOOTER } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerSitewideFooterLinkClickEvent = (label: React.ReactNode): void => {
	const gaEventPayload = {
		action: 'Footer link clicked',
		category: SITEWIDE_FOOTER,
		isLegacyEvent: false,
		label: label?.toString() || '',
	};

	const eventPayload = getSnowplowEventPayload({
		category: SITEWIDE_FOOTER,
		customData: { label },
		name: 'dlr_footer_link_click',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};
