import { event } from 'Services/gtm';
import { GAParams } from 'Types/ga';

const EVENT_CATEGORY = 'Navigation';
const USER_EVENT_LABEL = 'User menu';
const GUEST_EVENT_LABEL = 'Guest menu';
const getMarketplaceEvent = (labelSuffix: string) => ({
	action: 'Marketplace link clicked',
	event_action: 'Marketplace link clicked',
	event_category: EVENT_CATEGORY,
	event_label: `${USER_EVENT_LABEL} - ${labelSuffix}`,
});

export const GuestMenuAnalytics: { [key: string]: GAParams } = {
	howItWorks: {
		action: 'How it works link clicked',
		event_action: 'How it works link clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	logo: {
		action: 'Logo clicked',
		event_action: 'Logo clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	marketplace: {
		action: 'Marketplace link clicked',
		event_action: 'Marketplace link clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	motorwayMove: {
		action: 'MotorwayMove link clicked',
		event_action: 'MotorwayMove link clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	motorwayPay: {
		action: 'MotorwayPay link clicked',
		event_action: 'MotorwayPay link clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	motorwayTrade: {
		action: 'MotorwayTrade link clicked',
		event_action: 'MotorwayTrade link clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	products: {
		action: 'Products section clicked',
		event_action: 'Products section clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	signIn: {
		action: 'Sign in link clicked',
		event_action: 'Sign in link clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
	signUp: {
		action: 'Sign up link clicked',
		event_action: 'Sign up link clicked',
		event_category: EVENT_CATEGORY,
		event_label: GUEST_EVENT_LABEL,
	},
};

export const UserMenuAnalytics = {
	avatar: {
		action: 'User avatar clicked',
		event_action: 'User avatar clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	bidsAndOffers: {
		action: 'Bids & offers section clicked',
		event_action: 'Bids & offers section clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	bidsAndOffersActive: {
		action: 'Bids & offers - active link clicked',
		event_action: 'Bids & offers - active link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	bidsAndOffersDidntWin: {
		action: "Bids & offers - didn't win link clicked",
		event_action: "Bids & offers - didn't win link clicked",
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	bidsAndOffersUnderOffer: {
		action: 'Bids & offers - under offer link clicked',
		event_action: 'Bids & offers - under offer link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	billing: {
		action: 'Billing link clicked',
		event_action: 'Billing link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	helpCenter: {
		action: 'Help Center link clicked',
		event_action: 'Help Center link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	insights: {
		action: 'Insights link clicked',
		event_action: 'Insights link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	logo: {
		action: 'Logo clicked',
		event_action: 'Logo clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	marketplaceBuyItNow: getMarketplaceEvent('Buy it now'),
	marketplaceLiveSale: getMarketplaceEvent('Live sale'),
	motorwayMove: {
		action: 'MotorwayMove link clicked',
		event_action: 'MotorwayMove link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	motorwayPay: {
		action: 'MotorwayPay link clicked',
		event_action: 'MotorwayPay link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	motorwayTrade: {
		action: 'MotorwayTrade link clicked',
		event_action: 'MotorwayTrade link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	pricing: {
		action: 'Pricing link clicked',
		event_action: 'Pricing link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	products: {
		action: 'Products section clicked',
		event_action: 'Products section clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	purchases: {
		action: 'Purchases section clicked',
		event_action: 'Purchases section clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	purchasesCancelled: {
		action: 'Purchases - cancelled link clicked',
		event_action: 'Purchases - cancelled link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	purchasesComplete: {
		action: 'Purchases - completed link clicked',
		event_action: 'Purchases - completed link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	signOut: {
		action: 'Sign out clicked',
		event_action: 'Sign out clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	trade: {
		action: 'Trade link clicked',
		event_action: 'Trade link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
	wallet: {
		action: 'Wallet link clicked',
		event_action: 'Wallet link clicked',
		event_category: EVENT_CATEGORY,
		event_label: USER_EVENT_LABEL,
	},
};

export const triggerSiteNavigationGAEvent = ({
	eventName,
	loggedIn,
}:
	| { eventName: keyof typeof UserMenuAnalytics; loggedIn: true }
	| { eventName: keyof typeof GuestMenuAnalytics; loggedIn: false }): void =>
	event(loggedIn ? UserMenuAnalytics[eventName] : GuestMenuAnalytics[eventName]);

export const triggerUserSiteNavigationGAEvent = (eventName: keyof typeof UserMenuAnalytics): void =>
	triggerSiteNavigationGAEvent({ eventName, loggedIn: true });

export const triggerGuestSiteNavigationGAEvent = (eventName: keyof typeof GuestMenuAnalytics): void =>
	triggerSiteNavigationGAEvent({ eventName, loggedIn: false });
