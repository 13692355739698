/* eslint-disable camelcase */
import { ANALYTICS_CATEGORIES, SNOWPLOW_BACKEND_ENTITIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

import { getSnowplowEventPayload, logAnalyticsEvent } from '../../analytics';
import { SnowplowBackendTriggerEventSchema } from '../../analytics.types';

import { basePaymentsEvent } from './base/basePaymentEvent';
import { PaymentEventProps } from './base/basePaymentEvent.type';

const {
	PAYMENTS: { OTP },
} = ANALYTICS_CATEGORIES;

export function otpEvents<CustomData extends UnknownShapeObject>(props: PaymentEventProps<CustomData>): void {
	basePaymentsEvent({
		category: OTP,
		...props,
	});
}

interface OtpEventsBackendTrigger {
	action: string;
	backend_service: string;
	label: string;
}

export const otpEventsBackendTrigger = ({ action, backend_service, label }: OtpEventsBackendTrigger): void => {
	const eventPayload = getSnowplowEventPayload<SnowplowBackendTriggerEventSchema>({
		action: 'otp',
		backend_entity: SNOWPLOW_BACKEND_ENTITIES.PAYMENTS,
		backend_service,
		schemaName: SNOWPLOW_EVENTS.BACKEND_TRIGGER.SCHEMA,
		success: true,
		version: SNOWPLOW_EVENTS.BACKEND_TRIGGER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });

	logAnalyticsEvent({
		eventPayload: {
			action,
			category: OTP,
			isLegacyEvent: true,
			label,
		},
		provider: 'GOOGLE_ANALYTICS',
	});
};
