import React from 'react';
import cx from 'classnames';
import { AccordionContentProps, Content } from '@radix-ui/react-accordion';

import styles from './AccordionContent.module.scss';

const AccordionContent = React.forwardRef<HTMLDivElement, AccordionContentProps>(
	({ children, className, ...props }, forwardedRef) => (
		<Content className={cx(styles.content, className)} {...props} ref={forwardedRef}>
			{children}
		</Content>
	),
);

export default AccordionContent;
