import { MDText } from 'i18n-react';

import cypressIds from 'Components/cypress_ids.json';
import {
	triggerNavigationMotorwayMoveClickedEvent,
	triggerNavigationMotorwayPayClickedEvent,
	triggerNavigationMotorwayTradeClickedEvent,
} from 'Services/analytics/events';
import { GuestMenuAnalytics, UserMenuAnalytics } from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';
import { HorizontalAlignment } from 'Types/generic';
import ROUTES from 'Utilities/routes';

import LocalTexts from './Menu.texts';
import { LinkItemBaseProps } from './Menu.types';

const LocalT = new MDText(LocalTexts);

type GetProductNavMenuItemProps = {
	isTradeExperience?: boolean;
	isTransportExperience?: boolean;
	triggerGAEvent: (
		eventName: keyof typeof UserMenuAnalytics,
	) => void | ((eventName: keyof typeof GuestMenuAnalytics) => void);
};

export const getProductNavMenuItems = ({
	isTradeExperience,
	isTransportExperience,
	triggerGAEvent,
}: GetProductNavMenuItemProps): LinkItemBaseProps[][] => [
	[
		{
			as: ROUTES.MOTORWAY_PAY.as(),
			cypressId: cypressIds.navAccordion.products.motorwayPay,
			getActiveState: ({ pathname }) => pathname === ROUTES.MOTORWAY_PAY.href,
			href: ROUTES.MOTORWAY_PAY.href,
			onClick: (): void => {
				triggerGAEvent('motorwayPay');
				triggerNavigationMotorwayPayClickedEvent();
			},
			text: LocalT.translate('navMenuItemsProducts.motorwayPay'),
		},
		{
			as: ROUTES.MOTORWAY_MOVE.as(),
			cypressId: cypressIds.navAccordion.products.motorwayMove,
			getActiveState: ({ pathname }) => pathname === ROUTES.MOTORWAY_MOVE.href,
			hide: !isTransportExperience,
			href: ROUTES.MOTORWAY_MOVE.href,
			onClick: (): void => {
				triggerGAEvent('motorwayMove');
				triggerNavigationMotorwayMoveClickedEvent();
			},
			text: LocalT.translate('navMenuItemsProducts.motorwayMove'),
		},
		{
			as: ROUTES.MOTORWAY_TRADE.as(),
			cypressId: cypressIds.navAccordion.products.motorwayTrade,
			extensionPosition: HorizontalAlignment.Right,
			getActiveState: ({ pathname }) => pathname === ROUTES.MOTORWAY_TRADE.href,
			hide: !isTradeExperience,
			href: ROUTES.MOTORWAY_TRADE.href,
			onClick: (): void => {
				triggerGAEvent('motorwayTrade');
				triggerNavigationMotorwayTradeClickedEvent();
			},
			text: LocalT.translate('navMenuItemsProducts.motorwayTrade'),
		},
	],
];
