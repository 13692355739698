import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const { DEALER_WEBAPP_URL } = publicRuntimeConfig;

interface CleanUrlStringOptions {
	removeHash?: boolean;
	removeSearch?: boolean;
}

const defaultOptions = {
	removeHash: true,
	removeSearch: true,
};

const cleanUrlString = (urlString: string, options: CleanUrlStringOptions = defaultOptions): string => {
	const { removeHash, removeSearch } = options;
	const urlObj = new URL(urlString, DEALER_WEBAPP_URL);

	if (removeHash) {
		urlObj.hash = '';
	}

	if (removeSearch) {
		urlObj.search = '';
	}

	const result = urlObj.toString().replace(DEALER_WEBAPP_URL, '');

	return result;
};

export { cleanUrlString };
