import { useRouter } from 'next/router';
import qs from 'qs';

import { vehicleListRefreshObservable } from 'Components/VehicleList/VehicleList.helpers';
import { VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';
import { useResetFilters } from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterStore';
import { useSetListType } from 'Stores/ListStore/ListStore';
import { LIST_TYPES } from 'Utilities/consts';
import { clearCachedListState } from 'Utilities/helpers';

import { useClearVehicleListCache } from './useVehicleListCache/useClearVehicleListCache';
import { FALLBACK_BUTTON_CONFIG } from './useVehicleListButtonConfig.consts';
import {
	ButtonConfig,
	GetVehicleListQuery,
	IsForceFormatBasedOnTimeProps,
	UnauthorizedFallback,
	UseOnBrowseVehiclesClick,
} from './useVehicleListButtonConfig.types';

const { LIST_TYPE } = VEHICLE_LIST_FILTER_NAME;

export const stringifyQuery = (query: string | Record<string, unknown>): string =>
	qs.stringify(query, { addQueryPrefix: true });

export const isForceFormatBasedOnTime = ({ isSaleActive, listType }: IsForceFormatBasedOnTimeProps): boolean => {
	const isAuctionOutsideSaleTimes = !isSaleActive && listType === LIST_TYPES.auction;
	const isShortlist = listType === LIST_TYPES.shortlist;
	return isAuctionOutsideSaleTimes || isShortlist;
};

export const getVehicleListQuery = ({ adjustedListType, hasReceivedSaleTimes }: GetVehicleListQuery): string =>
	hasReceivedSaleTimes ? stringifyQuery({ [LIST_TYPE]: adjustedListType }) : '';

export const useOnBrowseVehiclesClick = ({
	adjustedListType,
	clearListCache,
	listType,
	refreshVehicleList,
	vehicleListQuery,
}: UseOnBrowseVehiclesClick): Noop => {
	const router = useRouter();
	const resetFilters = useResetFilters();
	const setListType = useSetListType();
	const { clearAllVehicleListCache } = useClearVehicleListCache();
	const hasVehicleListQueryChanged = vehicleListQuery !== stringifyQuery(router.query);

	const setTypeBasedOnFeature = () => {
		if (adjustedListType && listType !== adjustedListType) {
			setListType(adjustedListType);
		}
	};

	return () => {
		resetFilters();
		clearAllVehicleListCache();
		if (refreshVehicleList && !hasVehicleListQueryChanged) {
			vehicleListRefreshObservable.notify();
		}
		setTypeBasedOnFeature();

		// TODO: to remove when vehicle list legacy is removed
		if (clearListCache) {
			clearCachedListState();
		}
	};
};

export const getFallbackButtonConfig = (fallback: UnauthorizedFallback): ButtonConfig => {
	switch (fallback) {
		case 'signUp':
			return FALLBACK_BUTTON_CONFIG.signUp;
		case 'signIn':
			return FALLBACK_BUTTON_CONFIG.signIn;
		case 'index':
			return FALLBACK_BUTTON_CONFIG.index;
		default:
			throw new Error(`Invalid fallback: ${fallback}`);
	}
};
