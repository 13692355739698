import { MDText } from 'i18n-react';
import { NextRouter } from 'next/router';
import { z } from 'zod';

import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { toast } from '@motorway/mw-highway-code/pre-alpha';

import Texts from 'Components/pages/WithdrawFunds/WithdrawFunds.text';
import { getErrorInfoboxId } from 'Components/shared/ErrorInfobox/ErrorInfobox.helpers';
import { SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import { paymentWalletWithdraw } from 'Services/analytics/events/paymentEvents/paymentWalletWithdraw';
import { WithdrawalStatus } from 'Services/motorway/services/withdrawals/withdrawals.types';

import { PHONES } from '../VehiclePayment/VehiclePayment.helpers';
import { MonetaryValue } from '../Wallet/UI/MonetaryValue';
import { defaultWalletUrl } from '../Wallet/Wallet.const';

import { WithdrawFundsPageProps } from './WithdrawFunds.types';

const LocalT = new MDText(Texts);

const ACCOUNT_NUMBER_LENGTH_TO_SHOW = 4;

export const WITHDRAWAL_ERROR_INFOBOX_ID = `${getErrorInfoboxId()}`;

export const MIN_WITHDRAWAL_AMOUNT = 1 as const;

/**
 * The maximum amount that can be withdrawn without approval less that 100K
 *
 * @source https://motorway.atlassian.net/browse/PAY-2759
 */
export const MAX_WITHDRAWAL_AMOUNT_WITHOUT_APPROVAL = 99999.99 as const;

export const validateWithdrawalApprovalAmount = z.coerce.number().max(MAX_WITHDRAWAL_AMOUNT_WITHOUT_APPROVAL);

export const withdrawalContactNumber = (
	<Hyperlink href={`tel:${PHONES.WITHDRAW_FUNDS}`} label={PHONES.WITHDRAW_FUNDS} />
);

export const hasSuccessfulWithdrawal = (
	withdrawalStatus: WithdrawFundsPageProps['withdrawalStatus'],
): withdrawalStatus is WithdrawalStatus => {
	const { state } = withdrawalStatus || {};

	return state === 'done';
};

export const redirectToWalletWithSuccessMessage = async (props: {
	accountNumber: string;
	data: Pick<WithdrawalStatus, 'amount'>;
	router: Pick<NextRouter, 'push'>;
}): Promise<void> => {
	const { accountNumber, data, router } = props;

	await router.push(defaultWalletUrl);

	toast({
		content: LocalT.translate('success.message', {
			accountNumber: accountNumber.slice(ACCOUNT_NUMBER_LENGTH_TO_SHOW * -1),
			amount: <MonetaryValue value={data.amount} variant="regular" />,
		}),
		heading: LocalT.translate('success.title'),
		variant: 'success',
	});

	paymentWalletWithdraw<{
		label: string | number;
	}>({
		customData: {
			label: data.amount,
		},
		name: 'Funds withdrawn successfully toast shown',
		schemaName: SNOWPLOW_EVENTS.POPUP_APPEAR.SCHEMA,
		version: SNOWPLOW_EVENTS.POPUP_APPEAR.VERSION,
	});
};
