import React from 'react';
import dayjs from 'dayjs';

import { SiteNavigationComponent } from 'Storybook/local/SiteNavigation/SiteNavigation';
import { isTestEnv } from 'Utilities/helpers';

import { useNavigationState } from './SiteNavigation.helpers';

const SiteNavigation: React.FC = () => {
	const { hasBottomBorder, isHidden, isSticky, isTransparent } = useNavigationState();

	const displayNavigation = !isHidden;
	const shouldDisplayXmasLogo = !isTestEnv && dayjs().month() === 11;

	return (
		<React.Fragment>
			{displayNavigation && (
				<SiteNavigationComponent
					hasBottomBorder={!!hasBottomBorder}
					isSticky={isSticky}
					isTransparent={isTransparent}
					shouldDisplayXmasLogo={shouldDisplayXmasLogo}
				/>
			)}
		</React.Fragment>
	);
};

export { SiteNavigation };
