import {
	MoneyFigureFormatter,
	MoneyFigureVariant,
	MoneyFigureVariantClassNamesMap,
	THCTypographyVariantMap,
} from './MoneyFigure.types';

export const resolveMoneyFigureVariant = (variant: MoneyFigureVariant | undefined): MoneyFigureVariantClassNamesMap => {
	const variants: THCTypographyVariantMap<{
		Key: MoneyFigureVariant;
		Value: MoneyFigureVariantClassNamesMap;
	}> = {
		heading2: {
			base: 'thc-typography-heading2',
			fraction: 'thc-typography-large',
		},
		heading3: {
			base: 'thc-typography-heading3',
			fraction: 'thc-typography-heading3',
		},
		heading5: {
			base: 'thc-typography-heading5',
			fraction: 'thc-typography-heading5',
		},
		heading6: {
			base: 'thc-typography-heading6',
			fraction: 'thc-typography-heading6',
		},
		regular: {
			base: 'thc-typography-regular',
			fraction: 'thc-typography-regular',
		},
		small: {
			base: 'thc-typography-small',
			fraction: 'thc-typography-small',
		},
	};

	return (
		variants[variant || ''] ?? {
			base: undefined,
			fraction: undefined,
		}
	);
};

export const defaultMoneyFigureFractionFormatter: MoneyFigureFormatter = (v) => (v ? `.${v}` : '');

export const formatPrice: MoneyFigureFormatter = (value, opts = {}) =>
	Number(value).toLocaleString(opts.locale || 'en-GB', {
		currency: 'GBP',
		minimumFractionDigits: 0,
		signDisplay: 'auto',
		style: 'currency',
		...opts.options,
	});
