import React from 'react';
import cx from 'classnames';
import { AccordionMultipleProps, AccordionSingleProps, Root } from '@radix-ui/react-accordion';

import styles from './AccordionRoot.module.scss';

const AccordionRoot = React.forwardRef<HTMLDivElement, AccordionSingleProps | AccordionMultipleProps>(
	({ children, className, ...props }, forwardedRef) => (
		<Root className={cx(styles.root, className)} {...props} ref={forwardedRef}>
			{children}
		</Root>
	),
);

export default AccordionRoot;
