import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { isDev } from 'Utilities/helpers';

export type TimeId = Nullable<number>;

export interface BidCountSortStoreState {
	resetTimeId: () => void;
	setTimeId: (timeId: TimeId) => void;
	timeId: TimeId;
}

const bidCountSortStore = devtools<BidCountSortStoreState>(
	(set, get) => ({
		resetTimeId: () => {
			set({ timeId: null }, false, { type: 'resetTimeId' });
		},
		setTimeId: (timeId: TimeId) => {
			const { timeId: currentTimeId } = get();
			if (timeId === currentTimeId) {
				return;
			}
			set({ timeId }, false, { type: 'setTimeId' });
		},
		timeId: null,
	}),
	{
		enabled: Boolean(isDev),
		name: 'bidCountSortStore',
	},
);

export const useBidCountSortStore = create(bidCountSortStore);

export const useTimeId = (): BidCountSortStoreState['timeId'] => useBidCountSortStore((store) => store.timeId);
export const useSetTimeId = (): BidCountSortStoreState['setTimeId'] => useBidCountSortStore((store) => store.setTimeId);
export const useResetTimeId = (): BidCountSortStoreState['resetTimeId'] =>
	useBidCountSortStore((store) => store.resetTimeId);
