import React, { ReactElement } from 'react';
import cx from 'classnames';
import { NavigationMenuContent } from '@radix-ui/react-navigation-menu';

import Separator from 'Storybook/core/Separator/Separator';
import { Variant } from 'Types/variant';
import { applyCypressData } from 'Utilities/helpers';

import { ContentProps, LinkItemBaseProps } from '../../Menu.types';
import Link from '../Link/Link';

import styles from './Content.module.scss';

const Content = ({
	cypressId = 'nav-menu-content',
	headerContent,
	lists,
	router,
	side = 'center',
	size = 'regular',
	variant = Variant.PRIMARY,
	...props
}: ContentProps): ReactElement => (
	<NavigationMenuContent
		className={cx(styles.content, styles[side], styles[size], styles[variant])}
		{...applyCypressData(cypressId)}
		{...props}
	>
		<>
			{headerContent || null}
			{lists?.map((list: LinkItemBaseProps[], idx: number) => {
				const items = list.map(
					({
						cypressId: itemCypressId = 'menu-list-item',
						extensionContent,
						extensionPosition,
						getActiveState,
						hide,
						id,
						...itemProps
					}) =>
						!hide ? (
							<Link
								key={`${id}-${itemProps?.as}}`}
								isListItem
								extensionContent={extensionContent}
								extensionPosition={extensionPosition}
								id={id}
								isActive={getActiveState?.(router) || false}
								variant={variant}
								{...itemProps}
								{...applyCypressData(itemCypressId)}
							/>
						) : null,
				);

				if (idx !== lists.length - 1) {
					items.push(<Separator key={`separator-${idx}`} width="large" />);
				}

				return items;
			})}
		</>
	</NavigationMenuContent>
);

export default Content;
